import { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import { app } from '@microsoft/teams-js';
import { portalUrl, productId, apiKey, isMaintainentMode } from './settings/settings';
import logo from './logo.svg';
import './App.css';
import axios from 'axios';

interface Params {
  baseUrl: string
  headers: any
  method: string
}

const postConfig: Params = {
  baseUrl: portalUrl,
  headers: {
    "x-api-key": apiKey
  },
  method: 'post'
}

export const postAPI = async (data: any): Promise<any> => {
  return await axios({
    ...postConfig,
    url: `${postConfig.baseUrl}`,
    data
  }).then((response) => {
    console.log(response)
    return {
      status: response.status,
      data: response.data
    }
  }).catch((error) => {
    console.log(error)
    return {
      status: error.status,
      data: error.response
    }
  })
}

export default function App() {
  let [error, setError] = useState(0);
  let [username, setUsername] = useState('');
  let [classname, setClassname] = useState('App-logo');

  app.initialize()
    .then(() => {
      app.getContext()
        .then((context) => {
          setUsername(context.user?.userPrincipalName ?? '');
          console.log('user name', username);
        })
        .catch(() => {
          setError(-1);
          setClassname('');
        });
    })
    .catch(() => {
      setError(1);
      setClassname('');
    });

  useEffect(() => {
    function postData() {
      if (isMaintainentMode) {
        setError(4);
        setClassname('');
        return
      }
      let input = {
        "productId": productId,
        "user": username
      };

      if (username === '') {
        return;
      }
      postAPI(input).then(
        (res) => {
          if (res.status === 200) {
            redirect(username, res.data);
          }
          else {
            setError(-1);
            setClassname('');
          }
        }
      )
        .catch(() => {
          setError(-1);
          setClassname('');
        })
    }
    postData()
  }, [username]);
  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="App">
      <div >
        <div className='Main'>
          <img src={logo} alt="" width={48} height={48} className={classname}></img>
          {getDescription(error, username)}
        </div>
      </div>
    </div>
  );
}

function getDescription(error: number, username: string): JSX.Element {
  switch (error) {
    case 0:
      return (<p>Welcome to Teams CX Report</p>);
    case 1:
      return (<p>Failed to initialize Teams client</p>);
    case 2:
      return (<p>Failed to get Teams token</p>);
    case 3:
      return (<p>Invalid Teams token</p>);
    case 4:
      return (<p>Reporting app is in mantainent mode</p>);
    default: {
      if (username === '')
        return (<p>You don’t have permission to access reports.<br />Please contact your administrator for further information.</p>);
      else
        return (<p>User {username} lacks of privilege to access reports.<br />Please contact your administrator for further information.</p>);
    }
  }
}

function redirect(username: string, token: string) {
  var url = window.location.search;
  let newURL = url.replace("?url=", " ");
  // Decode the String
  let decodedUrl = Buffer.from(newURL, 'base64').toString('ascii');
  window.location.href = `${decodedUrl}&auth_token=${token}&var-user=${username}`;
}